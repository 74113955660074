import React, { useEffect, useState } from 'react';
import { getYearlyStatisticsFetchingAction } from '../../../../redux/actions/yearlyStatisticsActions';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import DataReactTable from '../../../Tables/DataTable/components/DataReactTable';
import { columns } from './components/YearlyStatisticsDataColumns';
import EyeIcon from 'mdi-react/EyeIcon';
import { getYearlyStatisticsBonuses } from '../../../../utils/api';
import YearlyStatisticsBonusesModal from './components/YearlyStatisticsBonusesModal';
import _, { isNull } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse, stringify } from 'query-string';


const YearlyStatistics = () => {
  const { yearlyStatistics, errors, totalRecords } = useSelector((state) => state.yearlyStatistics);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [bonuses, setBonuses] = useState([]);
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [query, setQuery] = useState({start: 0, limit: 10, search_query: '', user_id: ''});
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
    dispatch(getYearlyStatisticsFetchingAction(updatedQuery));
  }, [currentPage, pageLimit, dispatch, query, location.pathname, navigate]);

  const onSeeBonuses = (year) => {
    getYearlyStatisticsBonuses(year, authUser.accessToken).then(res => {
      if(res.data.success) {
        setBonuses(res.data.data)
        setModal(true);
      }
    })
  }

  const toggle = () => {
    setModal(!modal);
  }

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const getSearchValue = _.debounce((value) => {
    setQuery({...query, search_query: value});
  }, 600)

  return (
    <Container>
      <Row>
        <Col md={12}>
          <DataReactTable
            tableName={"Yearly Statistics"}
            sortTable={false}
            search={true}
            searchValue={getSearchValue}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit
            }}
            reactTableData={{tableHeaderData: columns, tableRowsData: yearlyStatistics?.map((c) => {
                return {
                  ...c,
                  id: `${c.id}`,
                  income: `$${isNaN(Number(c.income)) ? c.income : c.income.toFixed(2)}`,
                  year: `${c.year}`,
                  corp_events: `$${isNaN(Number(c.corp_events)) ? c.corp_events : c.corp_events.toFixed(2)}`,
                  difference: `${isNaN(Number(c.difference)) ? c.difference : c.difference.toFixed(2)}`,
                  expense_constant: `$${isNaN(Number(c.expense_constant)) ? c.expense_constant : c.expense_constant.toFixed(2)}`,
                  expense_salary: `$${isNull(c.expense_salary) ? '0.00' : isNaN(Number(c.expense_salary)) ? c.expense_salary : c.expense_salary.toFixed(2)}`,
                  bonuses: `$${isNaN(Number(c.bonuses)) ? c.bonuses : c.bonuses.toFixed(2)}`,
                  profit: `$${isNaN(Number(c.profit)) ? c.profit : c.profit.toFixed(2)}`,
                  balance: `$${isNaN(Number(c.balance)) ? c.balance : c.balance.toFixed(2)}`,
                  balance_account: `$${isNull(c.balance_account) ? '0.00' : isNaN(Number(c.balance_account)) ? c.balance_account : c.balance_account.toFixed(2)}`,
                  spent_corp_events: `$${isNaN(Number(c.spent_corp_events)) ? c.spent_corp_events : c.spent_corp_events.toFixed(2)}`,
                  investments: `$${isNaN(Number(c.investments)) ? c.investments : c.investments.toFixed(2)}`,
                  actions:
                    <div>
                      <button onClick={() => onSeeBonuses(c.year)} style={{border: 0, background: "transparent"}}><EyeIcon color="#b1c3c8" size={18} /></button>
                    </div>
                }
              })}} />
          {
            errors ? (<div>{errors}</div>) : null
          }
          {
            modal && (
              <YearlyStatisticsBonusesModal
                modal={modal}
                toggle={toggle}
                bonuses={bonuses}
              />
            )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default YearlyStatistics;
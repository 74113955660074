import { getBusinesses, getInvoice, getMethods } from '../../utils/api';

export const GET_INVOICE_FETCHING = 'GET_INVOICE_FETCHING';
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';

export const getInvoiceFail = (message) => {
  return { type: GET_INVOICE_FAIL, payload: { message } };
};

export const getInvoiceSuccess = (invoice, businesses) => {
  return { type: GET_INVOICE_SUCCESS, payload: { invoice, businesses } };
};

export const getInvoiceFetching = (id, accessToken) => {
  return async (dispatch, getState) => {
    const promisesArray = [];
    const businesses = [];

    dispatch({ type: GET_INVOICE_FETCHING });

    try {
      const businessRes = await getBusinesses('', accessToken);

      if (!businessRes.data.success) {
        throw new Error(
          Array.isArray(businessRes.data.errors)
            ? businessRes.data.errors[0]?.message
            : businessRes.data.errors.message,
        );
      }

      const invoiceRes = await getInvoice(id, accessToken);

      if (!invoiceRes.data.success) {
        throw new Error(
          Array.isArray(invoiceRes.data.errors)
            ? invoiceRes.data.errors[0]?.message
            : invoiceRes.data.errors.message,
        );
      }

      businessRes.data.data.businesses.forEach((item) => {
        businesses.push(item);
        promisesArray.push(getMethods(item.id, accessToken));
      });

      const responses = await Promise.all(promisesArray);

      responses.forEach((res, index) => {
        if (!res.data.success) {
          throw new Error(
            Array.isArray(res.data.errors)
              ? res.data.errors[0]?.message
              : res.data.errors.message,
          );
        }
        businesses[index].methods = res.data.data;
      });

      dispatch(getInvoiceSuccess(invoiceRes.data.data[0], businesses));
    } catch (error) {
      dispatch(getInvoiceFail(error.message));
    }
  };
};

import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { getInvoicesIsWithdrawn } from '../../../../../utils/api';
import renderSelectField from '../../../../../shared/components/form/Select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const FinancialReportStepsOneAutomaticIncomeForm = () => {
  const [invoices, setInvoices] = useState([]);
  const [disableSelect, setDisableSelect] = useState(false);
  const [invoicesId, setInvoicesId] = useState(null);
  let navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    getInvoicesIsWithdrawn(authUser.accessToken).then(res => setInvoices(res.data.data.invoices))
  }, [])

  const prevNavigationQuery = location.state?.prevQuery ? [...location.state.prevQuery] : [];

  return (
    <div className="card">
      <div className='card-body'>
        <form className='form'>
          <Col className='pl-0 col-12'>
            <div className='form__form-group'>
              <span className='form__form-group-label'>SELECT INVOICE</span>
              <div className='form__form-group-field'>
                <Field
                  name="invoices"
                  component={renderSelectField}
                  id="users"
                  value={""}
                  options={invoices?.map(u => {
                    return {
                      value: u.id,
                      label: `Invoices #${u.id} ${u.customer?.name}`
                    }
                  })}
                  onChange={(e) => {
                    if(e.value) {
                      setDisableSelect(!disableSelect);
                      setInvoicesId(e.value);
                    } else {
                      setDisableSelect(!disableSelect);
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className='pl-0 d-flex justify-content-end col-12'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={`${!disableSelect ? 'disabled' : ''}`}
              onClick={() => {
                navigate(`/dashboard/financial-reports/${params.id}/automatic-income/${invoicesId}`, {
                  state: { prevQuery: prevNavigationQuery, }
                });
              }}
            >Next</button>
          </Col>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'FinancialReportStepsAutomaticIncomeForm',
})(FinancialReportStepsOneAutomaticIncomeForm);
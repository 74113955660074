import React, { useState } from 'react';
import { Form, Field, reduxForm } from "redux-form";
import DropZoneField from "./dropzoneField";
import { postUserPhoto } from '../../../../../../utils/api';
import CustomModal from '../../../../../../shared/components/customComponents/CustomModal/CustomModal';
import { useSelector } from 'react-redux';

const imageIsRequired = value => (!value ? "Required" : undefined);

const UploadImageForm = ({ id, handleSubmit, submitting, pristine, reset }) => {
  const [imageFile, setImageFile] = useState([]);
  const [successChangeLogoModal, setSuccessChangeLogoModal] = useState(false);
  const [failedChangeLogoModal, setFailedChangeLogoModal] = useState(false);
  const [messageDeleteError, setMessageDeleteError] = useState('');
  const authUser = useSelector(state => state.auth.user);

  const handleFormSubmit = formProps => {
    let reader = new FileReader();
    reader.onloadend = function() {
      postUserPhoto(reader.result, authUser.accessToken).then(res => {
        if(res.data.success) {
          setSuccessChangeLogoModal(true);
        } else {
          setMessageDeleteError(res.data.errors[0].message);
          setFailedChangeLogoModal(true);
        }
      })
    }
    reader.readAsDataURL(formProps.imageToUpload.file);
  };

  const handleOnDrop = (newImageFile, onChange) => {
    const imageFile = {
      file: newImageFile[0],
      name: newImageFile[0].name,
      preview: URL.createObjectURL(newImageFile[0]),
      size: newImageFile[0].size
    };

    setImageFile([imageFile]);
    onChange(imageFile);
  };

  const resetForm = () => {
    setImageFile([]);
    reset();
  };

  return (
    <div className="app-container w-75" style={{margin: "0 auto"}}>
      <h3 className="title text-center">Change photo</h3>
      <hr />
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Field
          name="imageToUpload"
          component={DropZoneField}
          type="file"
          imagefile={imageFile}
          handleOnDrop={handleOnDrop}
          validate={[imageIsRequired]}
        />
        <div className="d-flex justify-content-between">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={submitting}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn btn-danger"
            disabled={pristine || submitting}
            onClick={resetForm}
          >
            Clear
          </button>
        </div>
      </Form>

      <CustomModal
        successModal={failedChangeLogoModal}
        toggleCloseModal={() => setFailedChangeLogoModal(!failedChangeLogoModal)}
        textModal={messageDeleteError}
        color={"danger"}
      />
      <CustomModal
        successModal={successChangeLogoModal}
        toggleCloseModal={() => {
          setSuccessChangeLogoModal(!successChangeLogoModal);
          window.location.reload();
        }}
        textModal={`Photo was successfully changed!`}
        color={"success"}
      />
    </div>
  );
}

export default reduxForm({ form: "UploadAccountImageForm" })(UploadImageForm);

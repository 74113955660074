import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { change, formValueSelector } from 'redux-form';
import { getInvoiceFetching } from '../../../../../redux/actions/editInvoiceActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import EditInvoiceForm from './EditInvoiceForm/EditInvoiceForm';
import { getMethods, putInvoice } from '../../../../../utils/api';

import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';
import AlertComponent from '../../../../../shared/components/customComponents/CustomAlert/CustomAlert';
import Loading from '../../../../../shared/components/Loading';

const EditInvoice = ({ selectBusiness }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errors, loading } = useSelector(state => state.editInvoice);
  const authUser = useSelector(state => state.auth.user);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [business, setBusiness] = useState([]);
  const [methods, setMethods] = useState([]);

  useEffect(() => {
    const getInvoiceFetchingThunk = getInvoiceFetching(id, authUser.accessToken);
    dispatch(getInvoiceFetchingThunk)
  }, [id, authUser.accessToken, dispatch, getInvoiceFetching])

  useEffect(() => {
    if (!selectBusiness) return;

    getMethods(selectBusiness.value, authUser.accessToken).then((res) => {
      if (res.data.success) {
        setMethods(res.data.data);
        dispatch(change('editInvoiceForm', 'select_method', { value: res.data.data[0].id, label: res.data.data[0].name }));
      }
    })
  }, [selectBusiness, dispatch, authUser.accessToken])

  const handleInvoiceEditForm = (values) => {
    putInvoice(values, id, authUser.accessToken)
      .then(res => {
        if (res.data.success !== true) {
          setErrorMessage(res.data.errors[0].message);
          setErrorModal(!errorModal);
        } else {
          navigate("/dashboard/invoices");
        }
      })
  }


  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Row>
        <Col xxl={6} xl={8} lg={10} className="col-12">
          <div className="card">
            {errors && (<AlertComponent color="danger">
              <p> {errors}</p>
            </AlertComponent>)}
            <div className="card-body pl-0 pr-0">
              <EditInvoiceForm
                onSubmit={handleInvoiceEditForm}
                business={business}
                methods={methods}
              />
            </div>
          </div>
          <CustomModal
            successModal={errorModal}
            toggleCloseModal={() => setErrorModal(!errorModal)}
            textModal={errorMessage}
            color={"danger"}
          />
        </Col>
      </Row>
    </Container>
  );
};

const selector = formValueSelector('editInvoiceForm');
export default connect(state => ({
  selectBusiness: selector(state, 'select_business')
}))(EditInvoice)
import React from 'react';
import { connect } from 'react-redux';
import { Route, Navigate, Routes, useLocation, useParams } from 'react-router-dom';
import Layout from '../../../Layout/index';
import { routes } from "./routesConfig";
import Breadcrumbs from "../Breadcrumbs";
import { matchRoutes } from 'react-router';

const WrappedRoutes = ({ auth, appConfig }) => {
    const { user } = auth;
    const { manageSaas } = appConfig;

    return (
        <div>
            <Layout />
            <div className="container__wrap">
              <Routes>
                {routes.map(({ path, Component, permission, checkSass }, key) => {
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      element={<InternalRouteElement permission={permission} Component={Component} checkSass={checkSass} user={user} manageSaas={manageSaas} />}
                    />
                  )
                })}
              </Routes>
            </div>
        </div>
    );
};

const InternalRouteElement = (props) => {
  const { permission, checkSass, Component, manageSaas, user } = props;
  const isAllowed = permission && permission.length ? permission.includes(user.role) : true;
  const location = useLocation();
  const params = useParams();
  const [{ route }] = matchRoutes(routes, location);
  const pathname = route.path;

  if (!isAllowed) {
    return <Routes>
      <Route
        path="*"
        element={<Navigate to="/dashboard" replace />}
      />
    </Routes>
  }

  if (checkSass) {
    if (!manageSaas.val) {
      return <Routes>
        <Route
          path="*"
          element={<Navigate to="/dashboard" replace />}
        />
      </Routes>
    }
  }

  delete params["*"];

  const crumbs = routes
    // Get all routes that contain the current one.
    .filter(({ path, exec }) => exec ? pathname === path : pathname.includes(path))
    // Swap out any dynamic routes with their param values.
    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
    .map(({ path, ...rest }) => {
      let name = rest.name;
      Object.keys(params).length && Object.keys(params).map((k) => {
        let reg = new RegExp(`#${k}`, "g");
        return name = name.replace(reg, `#${params[k]}`)
      })

      let p = Object.keys(params).length
      ? Object.keys(params).reduce(
        (_path, param) => _path.replace(
          `:${param}`, params[param]
          ), path
      )
      : path;

      if (location.state?.prevQuery) {
        const pathIndex = location.state.prevQuery.findIndex(item => item.path === p);
        
        if (pathIndex !== -1) {
          p += location.state.prevQuery[pathIndex].query;
        }         
      }

      return {
        path: p,
        ...rest, name
      }
    });

  return (
    <>
      <Breadcrumbs crumbs={crumbs} location={location} />
      <Component {...props} />
    </>
  );
}

export default connect(state => ({
    auth: state.auth,
    appConfig: state.appConfig,
}))(WrappedRoutes);
import {
  GET_INVOICE_FAIL,
  GET_INVOICE_FETCHING,
  GET_INVOICE_SUCCESS,
} from '../actions/editInvoiceActions';

const initialState = {
  editInvoice: null,
  businesses: [],
  loading: false,
  errors: '',
};

const editInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICE_FETCHING:
      return { ...state, loading: true };
    case GET_INVOICE_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        editInvoice: action.payload.invoice,
        businesses: action.payload.businesses,
        loading: false,
        errors: '',
      };
    default:
      return state;
  }
};

export default editInvoiceReducer;

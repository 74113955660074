import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Col, Container, Row} from "reactstrap";
import DataReactTable from "./../../Tables/DataTable/components/DataReactTable";
import {useDispatch, useSelector} from "react-redux";
import {columns} from "./SalaryReportsTableColumns.js";
import DownloadMultipleIcon from "mdi-react/DownloadMultipleIcon";
import { clearStateSalaryReport, getSalaryReportsAction } from '../../../redux/actions/salaryReportsActions';
import DownloadIcon from "mdi-react/DownloadIcon";
import PencilIcon from "mdi-react/PencilIcon";
import { CSVLink } from 'react-csv';
import { getSalaryReportPdfFile, getSalaryReportSpreadsheet } from '../../../utils/api';
import ConfirmModal from "../Projects/components/Environments/Variables/ConfirmModal/ConfirmModal";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import CustomModal from '../../../shared/components/customComponents/CustomModal/CustomModal';
import _ from 'lodash';
import preloaderIcon from "../../../shared/img/icons-preloader.gif";
import ReactTooltip from 'react-tooltip';
import { parse, stringify } from 'query-string';


const RowActions = ({ c, downloadFileDocument, downloadPDFFileDocument, csvData, csvLinkEl, dispatch, t, location }) => {
  const [disabled, setDisabled] = useState(false);
  const [loadingCsv, setLoadingCsv] = useState(false);

  return (
    <div className={"tableActions"}>
      {
        !c.is_locked ? (
          <NavLink
            state={{
              prevQuery: [{
                query: location.search,
                path: location.pathname,
              }]
            }} 
            to={`/dashboard/salary-reports/${c.id}/edit`} 
            onClick={() => dispatch(clearStateSalaryReport())}>
            <ReactTooltip id="editIcon" type="dark">
              <span style={{ color: 'white' }}>
                {t("reactTooltips.edit")}
              </span>
            </ReactTooltip>
            <button
              style={{border: 0, background: "transparent"}}
              data-tip
              data-for="editIcon"
            >
              <PencilIcon color="#b1c3c8" size={18} />
            </button>
          </NavLink>
        ) : null
      }

      <ReactTooltip id="loadIcon" type="dark">
        <span style={{ color: 'white' }}>
          {t("reactTooltips.downloadSpreadsheet")}
        </span>
      </ReactTooltip>
      <button disabled={loadingCsv} style={{border: 0, background: "transparent"}} data-tip data-for="loadIcon">
        {
          loadingCsv ? (
            <img src={preloaderIcon} alt='Loading' width="18px" height="18px"/>
          ) : (
            <DownloadMultipleIcon onClick={() => downloadFileDocument(c.id, (value) => setLoadingCsv(value))} color="#b1c3c8" size={18} />
          )
        }

        <CSVLink
          filename="SalaryReports"
          data={csvData}
          ref={csvLinkEl}
          separator={";"}
        />
      </button>
      <ReactTooltip id="loadPdfIcon" type="dark">
        <span style={{ color: 'white' }}>
          {t("reactTooltips.downloadPDF")}
        </span>
      </ReactTooltip>
      <button
        disabled={disabled}
        style={{border: 0, background: "transparent"}}
        onClick={() => {
          setDisabled(true);
          downloadPDFFileDocument(c.id, () => setDisabled(false));
        }}
        data-tip
        data-for="loadPdfIcon"
      >
        {
          disabled ? (<img src={preloaderIcon} alt='Loading' width="18px" height="18px"/>) : (<DownloadIcon color="#b1c3c8" size={18} />)
        }
      </button>
    </div>
  )
}


const SalaryReports = () => {
    const { t } = useTranslation('common');
    const {salary_reports, errors, totalRecords} = useSelector(state => state.salary_reports);
    const authUser = useSelector(state => state.auth.user);
    const navigate = useNavigate();
    const location = useLocation();
    const parsedQuery = parse(location.search);
    const dispatch = useDispatch();
    const csvLinkEl = React.useRef(null);
    const [successModal, setSuccessModal] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [modal, setModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
    const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
    const [query, setQuery] = useState({start: 0, limit: 10, search_query: '', user_id: authUser.userId});


    useEffect(() => {
      const updatedQuery = {
        ...query,
        start: (currentPage - 1) * pageLimit,
        limit: pageLimit
      };

      navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
      dispatch(getSalaryReportsAction(updatedQuery));
    }, [currentPage, pageLimit, dispatch, query, authUser.role, location.pathname, navigate]);

    const downloadFileDocument = async (id, callback) => {
      let isError = false;

      callback(true);
        await getSalaryReportSpreadsheet(id, authUser.accessToken).then(res => {
          if (res.data.success) {
            setErrorMessage('');
            setCsvData(res.data.data.csv);
          } else {
            setCsvData([]);
            setErrorMessage(res.data.errors[0].message);
            setModal(true);
            isError = true;
          }
        })

        setTimeout(() => {
          callback(false);
          if(isError) return;
          csvLinkEl.current.link.click();
        })
    }

    const toggleCloseModal = () => {
        setModal(!modal)
    }
    const downloadPDFFileDocument = (id, callback) => {
        getSalaryReportPdfFile(id, authUser.accessToken)
            .then(response => {
                const fileURL = 'data:application/octet-stream;base64,' + response.data.data.pdf;
                let a = document.createElement('a');
                a.href = fileURL;
                a.download = `${response.data.data.name}`;
                a.click();
                callback();
            })
            .catch(e => {
                setSuccessModal(true);
                callback();
            })
    }
    const toggle = () => {
        setSuccessModal(!successModal);
    }

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangePageSize = (newSize) => {
        setCurrentPage(1);
        setPageLimit(newSize);
    };

    const getSearchValue = _.debounce((value) => {
        if (value === "0") return;

        setQuery({...query, search_query: value});
    }, 600)

    return (
        <Container className="dashboard">
            <Row>
                <Col className="d-flex justify-content-end">
                    <button className="btn btn-success" onClick={() => {
                      navigate("/dashboard/salary-reports/add", {
                        state: {
                          prevQuery: [{
                            query: location.search,
                            path: location.pathname,
                          }]
                        }
                      });
                    }}>Create Report</button>
                </Col>
                <Col md={12}>
                    <DataReactTable
                      tableName={"Salary reports"}
                      sortTable={false}
                      search={true}
                      searchValue={getSearchValue}
                      pagination={{
                          onChangePageSize: handleChangePageSize,
                          onChangePage: handleChangePage,
                          currentPage,
                          totalRecords,
                          pageLimit
                      }}
                      emptyStateMessage={t('empty_states.reports')}
                      reactTableData={{tableHeaderData: columns, tableRowsData: salary_reports?.map((c) => {
                              return {
                                  ...c,
                                  id: `${c.id}`,
                                  total_lists: `${c.total_lists + " (" + c.total_employees + ")"}`,
                                  total_salary: (c.total_salary).toFixed(2),
                                  actions: (<RowActions
                                    c={c}
                                    downloadPDFFileDocument={downloadPDFFileDocument}
                                    downloadFileDocument={downloadFileDocument}
                                    csvData={csvData}
                                    csvLinkEl={csvLinkEl}
                                    dispatch={dispatch}
                                    t={t}
                                    location={location}
                                    />
                                  )
                              }
                          })}}
                    />
                    {
                        errors ? (<div>{errors}</div>) : null
                    }
                </Col>
                <ConfirmModal message={"Salary report list not found"}
                              color={"primary"}
                              modal={successModal}
                              toggle={toggle}
                              onNext={toggle}
                />
                <CustomModal
                  successModal={modal}
                  toggleCloseModal={toggleCloseModal}
                  textModal={errorMessage}
                  color={"danger"}
                />
            </Row>
        </Container>
    );
};

export default SalaryReports;
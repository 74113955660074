import React, { useEffect, useState } from 'react';
import { getSettingsFetchingAction } from '../../../../redux/actions/settingsActions';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import DataReactTable from '../../../Tables/DataTable/components/DataReactTable';
import PencilIcon from 'mdi-react/PencilIcon';
import CustomModal from '../../../../shared/components/customComponents/CustomModal/CustomModal';
import {columns} from "./DataReactTableColumns";
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { putSettings } from '../../../../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse, stringify } from 'query-string';


export const validateEditFields = (values) => {
  const errors = {};

  if (!values.value) {
    errors.value = 'Value can not be blank!';
  } else if(values.type === "INT" && !/^[0-9.]*$/.test(values.value)) {
    errors.value = 'The field should contain only integers!';
  } else if(values.type === "FLOAT" && !/^\d+\.\d+$/.test(values.value)) {
    errors.value = 'The field should contain only floating point numbers!';
  }

  return errors;
};

const System = () => {
  const authUser = useSelector(state => state.auth.user);
  const {settings, errors, totalRecords} = useSelector(state => state.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [editData, setEditData] = useState({})
  const [editModes, setEditModes] = useState({});
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [validationsErrors, setValidationErrors] = useState({});
  const [failedEditModal, setFailedEditModal] = useState(false);
  const [errorEditMessage, setErrorEditMessage] = useState('');
  const [successEditModal, setSuccessEditModal] = useState(false);
  const [query, setQuery] = useState({start: 0, limit: 10, search_query: ''});


  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    if (JSON.stringify(updatedQuery) !== JSON.stringify(query)) {
      setQuery(updatedQuery);
    }
  }, [query, currentPage, pageLimit]);

  useEffect(() => {
    navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
    dispatch(getSettingsFetchingAction(query));
  }, [dispatch, query, refreshCounter, location.pathname, navigate, currentPage, pageLimit]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditData((prevEditedData) => ({
      ...prevEditedData,
      [name]: value,
    }));
  };

  const handleEditClick = () => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      value: editData.value
    }))
  };

  const handleEditReport = (key, updatedData) => {
    putSettings(key, updatedData, authUser.accessToken).then(res => {
      if(!res.data.success) {
        setErrorEditMessage(res.data.errors[0].message);
        setFailedEditModal(!failedEditModal);
      } else {
        setSuccessEditModal(!successEditModal);
      }
    })
  };

  const handleSaveClick = () => {
    const updatedData = {
      value: editData.type !== "STRING" ? Number(editData.value) : editData.value,
      type: editData.type
    };

    const errors = validateEditFields(updatedData);

    if (!_.isEmpty(errors)) {
      return setValidationErrors(errors);
    }

    handleEditReport(editData.key, updatedData);
    setEditModes('');
  };

  const getSearchValue = _.debounce((value) => {
    if (value === "0") return;

    setQuery({...query, search_query: value});
  }, 600)

  return (
    <Container className="settings">
      <Row>
        <Col md={12}>
          <DataReactTable
            tableName={"Settings"}
            classList={"settings"}
            sortTable={false}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit
            }}
            search={true}
            searchValue={getSearchValue}
            reactTableData={{tableHeaderData: columns, tableRowsData: settings?.map((c) => {
              const isEditMode = editModes === c.key;

              return {...c,
                id: `${c.id}`,
                value: !isEditMode ? c.value : (
                  <>
                    <input
                      type="text"
                      name="value"
                      value={editData.value}
                      onChange={(e) => handleInputChange(e, c.value)}
                      style={{width: "95%"}}
                    />
                    <span style={{color: "red", display: `${_.isEmpty(validationsErrors) ? "none" : "block"}`, paddingBottom: 0}}>{validationsErrors.value}</span>
                  </>
                ),
                actions:
                  <div className={"tableActions"}>
                    {isEditMode ? (
                        <div className="d-flex w-100">
                          <button className={"save btn btn-success"} onClick={() => handleSaveClick(c)}>Save</button>
                          <button className={"cancel btn btn-danger"} onClick={() => {setEditModes(''); setValidationErrors('')}}>Cancel</button>
                        </div>
                      ) : (
                        <>
                          <ReactTooltip id="editIcon" type="dark">
                            <span>
                              Edit
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            className="btn-edit"
                            data-for="editIcon"
                            onClick={() => {
                              handleEditClick();
                              setEditData(c);
                              setEditModes(c.key);
                            }}
                          >
                            <PencilIcon color="#b1c3c8" size={18} />
                          </button>
                        </>
                      )
                    }
                  </div>
              }})
            }} />
          {
            errors ? (<div>{errors}</div>) : null
          }
          <CustomModal
            successModal={successEditModal}
            toggleCloseModal={() => {
              setSuccessEditModal(!successEditModal);
              setRefreshCounter(refreshCounter + 1);
            }}
            textModal={`Setting #${editData.id} has been updated!`}
            color={"success"}
          />
          <CustomModal
            successModal={failedEditModal}
            toggleCloseModal={() => setFailedEditModal(!failedEditModal)}
            color={"danger"}
            textModal={errorEditMessage}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default System;
import {
  GET_YEARLY_STATISTICS_FAIL,
  GET_YEARLY_STATISTICS_FETCHING,
  GET_YEARLY_STATISTICS_SUCCESS,
} from '../actions/yearlyStatisticsActions';

const initialState = {
  yearlyStatistics: [],
  loading: false,
  errors: '',
  totalRecords: 0,
};

const yearlyStatistics = (state = initialState, action) => {
  switch (action.type) {
    case GET_YEARLY_STATISTICS_FETCHING:
      return { ...state, loading: true };
    case GET_YEARLY_STATISTICS_FAIL:
      return { ...state, loading: false, errors: action.payload.message };
    case GET_YEARLY_STATISTICS_SUCCESS:
      return {
        ...state,
        yearlyStatistics: action.payload.financial_reports.sort(
          (a, b) => b.year - a.year,
        ),
        totalRecords: Number(action.payload?.total_records),
        loading: false,
        errors: '',
      };
    default:
      return state;
  }
};

export default yearlyStatistics;

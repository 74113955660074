import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { getInfoBonuses } from '../../../../../utils/api';
import _ from "lodash";
import Roles from '../../../../../config/roles';
import { useSelector } from 'react-redux';


const FinancialReportBonusesModal = ({toggle, modal, incomeId}) => {
  const [bonuses, setBonuses] = useState({});
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    getInfoBonuses(incomeId, authUser.accessToken)
      .then(res => setBonuses(res.data.data));
  }, [incomeId])

  const participants = useMemo(() => {
    const participants = [];

    if(_.isEmpty(bonuses)) return participants;

    for (const l of bonuses?.list) {
      const keys = _.keys(l.participants);
      const mParticipants = _.map(_.values(l?.participants), (p, i) => ({
        ...p,
        id: keys[i]
      }))
      participants.push(mParticipants);
    }

    const groupedParticipants = _.groupBy(_.uniqWith(
      _.map(_.flatten(participants), p => {
        return {
        ...p,
        userExpenses: _.toNumber(p.expenses),
        userIncome: _.toNumber(p.income),
        debts: _.sum(_.map(_.values(p?.projectsData), d => _.toNumber(d.debts))),
        bonus: _.sum(_.map(_.values(p?.projectsData), d => _.toNumber(d.bonus))),
      }}), _.isEqual), 'id');

    const res = [];

    for (const [key, participants] of _.toPairs(groupedParticipants)) {
      const firstParticipant = _.first(participants);
      res.push({
        id: key,
        name: firstParticipant.dev_name,
        userExpenses:  _.sumBy(participants, 'userExpenses'),
        userIncome: _.sumBy(participants, 'userIncome'),
        debts: _.sumBy(participants, 'debts'),
        bonus: _.sumBy(participants, 'bonus'),
      })
    }

    return res;
  }, [bonuses])


  return (
    <Modal toggle={toggle} className="theme-light ltr-support add-income" isOpen={modal} style={{maxWidth: "800px", padding: 0}}>
      <ModalHeader>VIEW INFO</ModalHeader>
      <ModalBody>
        <Table responsive>
          <thead>
            <tr className="text-center">
              <th>Added By</th>
              {
                authUser.role !== Roles.FIN && (
                  <>
                    <th>Expenses</th>
                    <th>Income</th>
                  </>
                )
              }
              <th>Old Debts</th>
              <th>New Debts</th>
              <th>Written off debts</th>
              <th>Bonus</th>
            </tr>
          </thead>
          <tbody>
          {
            bonuses?.list?.map((b, i) => {
              return (
                <tr key={i} className="text-center">
                  <td>{b.added_by.name}</td>
                  {
                    authUser.role !== Roles.FIN && (
                      <>
                        <td>${b.expenses}</td>
                        <td>${b.income}</td>
                      </>
                    )
                  }
                  <td>${b.applied_debts}</td>
                  <td>${b.added_debts}</td>
                  <td>${b.current_debts}</td>
                  <td>${b.bonuses}</td>
                </tr>
              );
            })
          }
          <tr className="bold-text">
            <td colSpan='6'>Salary Expenses:</td>
            <td className="text-right">${bonuses?.sumOfExpensesForCommercialProjectsByApprovedHours}</td>
          </tr>
          <tr className="bold-text">
            <td colSpan='6'>Total Overhead:</td>
            <td className="text-right">${bonuses?.tco}</td>
          </tr>
          <tr className="bold-text">
            <td colSpan='6'>Total:</td>
            <td className="text-right">${bonuses?.tce}</td>
          </tr>
          {
            authUser.role !== Roles.FIN && (
              <tr className='bold-text'>
                <td colSpan='6'>Income Total:</td>
                <td className='text-right'>${bonuses?.income}</td>
              </tr>
            )
          }
          <tr className='bold-text'>
            <td colSpan='6'>Bonus Total:</td>
            <td className='text-right'>${bonuses?.bonus}</td>
          </tr>
          </tbody>
        </Table>
        <Table responsive className="mt-3">
          <thead>
            <tr className="text-center">
              <th>Name</th>
              {
                authUser.role !== Roles.FIN && (
                  <>
                    <th>Expenses</th>
                    <th>Income</th>
                  </>
                )
              }
              <th>Debts</th>
              <th>Bonus</th>
            </tr>
          </thead>
          <tbody>
          {
            participants.map((p) => {
              return (
                <tr key={p.id} className="text-center">
                  <td>{p.name}</td>
                  {
                    authUser.role !== Roles.FIN && (
                      <>
                        <td>${_.round(p.userExpenses, 2)}</td>
                        <td>${_.round(p.userIncome, 2)}</td>
                      </>
                    )
                  }
                  <td>${_.round(p.debts, 2)}</td>
                  <td>${_.round(p.bonus, 2)}</td>
                </tr>
              );
            })
          }
          <tr className="bold-text text-center">
            <td>Total</td>
            {
              authUser.role !== Roles.FIN && (
                <>
                  <td>${_.round(_.sumBy(participants, 'userExpenses'), 2)}</td>
                  <td>${_.round(_.sumBy(participants, 'userIncome'), 2)}</td>
                </>
              )
            }
            <td>${_.round(_.sumBy(participants, 'debts'), 2)}</td>
            <td>${_.round(_.sumBy(participants, 'bonus'), 2)}</td>
          </tr>
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default FinancialReportBonusesModal;
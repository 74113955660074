import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import {
  getAllProjects,
  getAllUsers,
  getManagementDownloadPDF,
  putManagementApprove,
  putManagementDisapprove,
} from '../../../utils/api';
import ManagementFilterForm from './components/ManagementFilterForm/ManagementFilterForm';
import moment from 'moment';
import CustomModal from '../../../shared/components/customComponents/CustomModal/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { getManagementFetchingAction } from '../../../redux/actions/managementActions';
import DataReactTable from '../../Tables/DataTable/components/DataReactTable';
import { columns } from './components/ManagementDataColumns';
import ManagementDisapproveModalForm from './components/ManagementDisapproveModalForm/ManagementDisapproveModalForm';
import _ from 'lodash';
import Roles from '../../../config/roles';
import { useSearchParams } from 'react-router-dom';
import loaderIcon from '../../../shared/img/loader.gif';


const TableButton = ({isActive, id, handleApproveReport, setDeactivateId, setDeactivateModal, dataManagementReport, setDataManagementReport, isLoading, loadingAction, disabled}) => {
  const [isHovered, setIsHovered] = useState(false);

  const getMetaData = () => {
    const obj = {
      btnLabel: "",
      btnClass: ""
    }
    if(isActive) {
      obj.btnLabel = "Approved";
      obj.btnClass = "btn-activated";
    }
    if(isHovered && isActive) {
      obj.btnLabel = "Disapprove";
      obj.btnClass = "btn-deactivated";
    }

    if(!isActive) {
      obj.btnLabel = "Disapproved";
      obj.btnClass = "btn-deactivated";
    }
    if(!isActive && isHovered) {
      obj.btnLabel = "Approve";
      obj.btnClass = "btn-activated";
    }

    if (isLoading && loadingAction === 'activating') {
      obj.btnLabel = "Approving...";
    }

    return obj;
  }

  const {btnLabel, btnClass} = getMetaData();

  return (
    <button
      className={btnClass}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
      onClick={() => {
        if(!isActive) {
          handleApproveReport(id);
        } else {
          setDataManagementReport(dataManagementReport);
          setDeactivateId(id);
          setDeactivateModal(true);
        }
      }}
    >{isLoading ? <img src={loaderIcon} alt='Loading' style={{width: "15px", marginRight: "5px"}} /> : null}{btnLabel}</button>
  )
}


const Management = () => {
  const {management, errors, totalRecords, totalHours, totalCost} = useSelector(state => state.management);
  const authUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState(null);
  const [projects, setProjects] = useState(null);
  const fromDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD");
  const toDate = moment().format("YYYY-MM-DD");  
  const [query, setQuery] = useState({
    from_date: searchParams.get("from_date") ? searchParams.get("from_date") : fromDate,
    to_date: searchParams.get("to_date") ? searchParams.get("to_date") : toDate,
    project_id: searchParams.get("project_id") ? searchParams.get("project_id") : '',
    user_id: searchParams.get("user_id") ? searchParams.get("user_id") : '',
    start: searchParams.get("start") ? searchParams.get("start") : 0,
    limit: searchParams.get("limit") ? searchParams.get("limit") : 10,
    search_query: searchParams.get("search_query") ? searchParams.get("search_query") : ''
  });
  const [disabled, setDisabled] = useState(false);
  const [failedDownloadPdfModal, setFailedDownloadPdfModal] = useState(false);
  const [messageErrorDownloadPdf, setMessageErrorDownloadPdf] = useState('');
  const [messageActivateError, setMessageActivateError] = useState('');
  const [failedActivateModal, setFailedActivateModal] = useState(false);
  const [deactivateId, setDeactivateId] = useState(null);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [messageDeactivateError, setMessageDeactivateError] = useState('');
  const [failedDeactivateModal, setFailedDeactivateModal] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [dataManagementReport, setDataManagementReport] = useState({});
  const notes = {
    note_one: "Approved reports are in green",
    note_two: "Not Approved reports are in grey (Non Approved reports will not be paid)"
  }
  const [loadingProjectId, setLoadingProjectId] = useState(null);
  const [loadingAction, setLoadingAction] = useState('');

  useEffect(() => {
    getAllUsers(authUser.accessToken).then(res => setUsers(res.data.data.users))
  }, [authUser.accessToken]);

  useEffect(() => {
    getAllProjects(authUser.accessToken).then(res => setProjects(res.data.data.projects))
  }, [authUser.accessToken]);

  useEffect(() => {
    dispatch(getManagementFetchingAction(query));
  }, [dispatch, query, refreshCounter]);

  const onDownloadPDF = (values) => {
    setDisabled(true);
    getManagementDownloadPDF({
      project_id: +values.selectProject.value, 
      user_id: +values.selectReporter.value, 
      from_date: values.from_date ? moment(values.from_date).format("YYYY-MM-DD") : fromDate, 
      to_date: values.to_date ? moment(values.to_date).format("YYYY-MM-DD") : toDate,
    }, authUser.accessToken)
      .then(res => {
        if(res.data.success) {
          const fileURL = 'data:application/octet-stream;base64,' + res?.data?.data?.pdf;
          let a = document.createElement('a');
          a.href = fileURL;
          a.download = `${res.data.data.name}`;
          a.click();
          setDisabled(false);
        }

        setMessageErrorDownloadPdf(res.data.errors[0].message);
        setFailedDownloadPdfModal(true);

      })
  }

  const resetFilterForm = () => {
    setSearchParams();
    return setQuery({
      ...query, 
      start: 0,
      from_date: fromDate, 
      search_query: '', 
      project_id: '', 
      user_id: '', 
      to_date: toDate,
    });
  }

  const managementFilter = (value) => {
    const newQuery = {
      ...query,
      start: 0,
      search_query: value?.search,
      project_id: value?.selectProject?.value,
      user_id: value?.selectReporter?.value,
      from_date: value.from_date ? moment(value.from_date).format("YYYY-MM-DD") : fromDate,
      to_date: value.to_date ? moment(value.to_date).format("YYYY-MM-DD") : toDate,
      is_internal: value?.is_internal ? 1 : 0
    };

    setSearchParams(newQuery);

    return setQuery(newQuery);
  }

  const handleChangePage = (newPage) => {
    setQuery((prevQuery) => {
      const newQuery = {
        ...prevQuery,
        start: (newPage - 1) * prevQuery.limit,
      };

      setSearchParams(newQuery);
      return newQuery;
    });
  };

  const handleChangePageSize = (newSize) => {    
    setQuery((prevQuery) => {
      const newQuery = {
        ...prevQuery,
        start: 0,
        limit: newSize
      };

      setSearchParams(newQuery);
      return newQuery;
    });
  };

  const handleApproveReport = (activateId) => {
    setLoadingProjectId(activateId);
    setDisabled(true);
    setLoadingAction('activating');
    putManagementApprove(activateId, authUser.accessToken).then(res => {
      if(res.data.success) {
        setDisabled(false);
        setLoadingProjectId(null);
        setRefreshCounter(refreshCounter + 1);
      } else {
        setMessageActivateError(res.data.errors[0].message);
        setFailedActivateModal(!failedActivateModal);
      }
    })
  }

  const handleDisapproveReport = (hours) => {
    putManagementDisapprove(deactivateId, hours, authUser.accessToken).then(res => {
      if(res.data.success) {
        setDeactivateModal(!deactivateModal);
        setRefreshCounter(refreshCounter + 1);
      } else {
        setMessageDeactivateError(res.data.errors[0].message);
        setFailedDeactivateModal(!failedDeactivateModal);
      }
    })
  }

  const checkHiddenColumns = () => {
    if(['CLIENT'].includes(authUser.role)) {
      return ['cost', 'actions'];
    }

    return [];
  }

  return (
    <Container>
      <Row>
        <ManagementFilterForm
          users={users}
          onSubmit={managementFilter}
          resetForm={resetFilterForm}
          projects={projects}
          onDownloadPDF={onDownloadPDF}
          disabled={disabled}
          userRole={authUser.role}
          query={query}
        />
        <Col className="col-12 mt-5 mb-2">
          <span style={{color: "#80838e", fontSize: "13px"}}>
            Total {totalHours} Hours
            {authUser.role !== Roles.CLIENT && (`, Total ${totalCost}`)}
          </span>
        </Col>
        <Col className="cool-12">
          <DataReactTable
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage: (query.start / query.limit) + 1,
              totalRecords,
              pageLimit: query.limit,
            }}
            notes={notes}
            sortTable={true}
            search={false}
            reactTableData={{tableHeaderData: _.filter(columns, c => !checkHiddenColumns().includes(c.accessor)), tableRowsData: management?.map((c) => {
              return {...c,
                id: `${c.report_id}`,
                actions: (
                  <TableButton
                    isActive={!!c.is_approved}
                    id={c.report_id}
                    handleApproveReport={handleApproveReport}
                    setDeactivateId={setDeactivateId}
                    setDeactivateModal={setDeactivateModal}
                    dataManagementReport={c}
                    setDataManagementReport={setDataManagementReport}
                    isLoading={loadingProjectId === c.report_id}
                    loadingAction={loadingAction}
                    disabled={disabled}
                  />
                ),
                rowCustomClass: { className: c.is_approved ? "approved" : "not-approved" },
              }
            })}}
          />

          {
            errors ? (<div>{errors}</div>) : null
          }
        </Col>

        <ManagementDisapproveModalForm
          successModal={deactivateModal}
          toggleCloseModal={() => setDeactivateModal(!deactivateModal)}
          confirm={handleDisapproveReport}
          reportData={dataManagementReport}
        />

        <CustomModal
          successModal={failedDownloadPdfModal}
          toggleCloseModal={() => setFailedDownloadPdfModal(!failedDownloadPdfModal)}
          textModal={messageErrorDownloadPdf}
          color={"danger"}
        />
        <CustomModal
          successModal={failedActivateModal}
          toggleCloseModal={() => setFailedActivateModal(!failedActivateModal)}
          textModal={messageActivateError}
          color={"danger"}
        />
        <CustomModal
          successModal={failedDeactivateModal}
          toggleCloseModal={() => setFailedDeactivateModal(!failedDeactivateModal)}
          textModal={messageDeactivateError}
          color={"danger"}
        />
      </Row>
    </Container>
  );
};

export default Management;
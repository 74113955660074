import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormCreateSalaryList from './FormCreateSalaryList/FormCreateSalaryList';
import { postCreateSalaryList } from '../../../../../utils/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';

const CreateSalaryList = () => {
  const {id} = useParams();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const authUser = useSelector(state => state.auth.user);

  const handleCreateSalaryList = (value) => {
    postCreateSalaryList(id, value, authUser.accessToken).then((res) => {
      if(res.data.success) {
        return setSuccessModal(!successModal);
      }

      setErrorMessage(res.data.errors[0].message);
      setErrorModal(!errorModal);
    })
  }


  const prevNavigationQuery = location.state?.prevQuery ? [...location.state.prevQuery] : [];

  return (
    <Container>
      <Row>
        <Col>
          <FormCreateSalaryList onSubmit={handleCreateSalaryList} />
        </Col>
        <Col className="col-12">
          <Col className="col-12">
            <p>{t('financial_salaries.tax_label')}</p>
          </Col>
        </Col>

        <CustomModal
          successModal={successModal}
          toggleCloseModal={() => {
            setSuccessModal(!successModal);
            navigate(`/dashboard/salary-reports/${id}/edit`, {
              state: { prevQuery: prevNavigationQuery, }
            });
          }}
          textModal={`Salary list was successfully created!`}
          color={"success"}
        />
        <CustomModal
          successModal={errorModal}
          toggleCloseModal={() => setErrorModal(!errorModal)}
          color={"danger"}
          textModal={errorMessage}
        />
      </Row>
    </Container>
  );
};

export default CreateSalaryList;
import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Tooltip from 'rc-tooltip';

const SidebarLink = ({
  title, icon, newLink, route, onClick, isQuestionIcon, tooltipText
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    className={({isActive}) => isActive ? 'sidebar__link-active' : ''}
  >
    <li className="sidebar__link">
      {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
      <p className="sidebar__link-title">
        {title}
        {isQuestionIcon ? (
          <>
            <Tooltip placement='top'
                     trigger={['hover']}
                     overlayStyle={{width: "100%", maxWidth: "250px", background: "rgba(0, 0, 0, 0.7)", borderRadius: "10px", color: "white", padding: "10px"}}
                     zIndex={9999}
                     destroyTooltipOnHide={true}
                     overlay={<span>{tooltipText}</span>}
            >
              <img src={`${process.env.PUBLIC_URL}/img/question-circle.svg`}
                   alt='Question Circle'
                   style={{ maxWidth: '15px', marginLeft: '5px' }}
              />
            </Tooltip>
          </>
        ) : null}
        {newLink ? <Badge className='sidebar__link-badge'><span>New</span></Badge> : ''}
      </p>
    </li>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
};

export default SidebarLink;

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  CustomizerProps,
  ThemeProps,
  RTLProps,
} from '../../shared/prop-types/ReducerProps';
import { fetchManageSaas } from '../../redux/actions/appConfigActions';
import { authRequest, authChecked } from '../../redux/actions/authActions';
import Loading from '../../shared/components/Loading';
import { backToLogin, withRouter } from '../../utils/helpers';
import Cookies from 'js-cookie';

const wrapperClass = (customizer) => {
  classNames({
    wrapper: true,
    'squared-corner-theme': customizer.squaredCorners,
    'blocks-with-shadow-theme': customizer.withBoxShadow,
    'top-navigation': customizer.topNavigation,
  });
};

const cookieUser = Cookies.get('storageUser')
  ? JSON.parse(Cookies.get('storageUser'))
  : null;

const direction = (location, rtl) =>
  location.pathname === '/' ? 'ltr' : rtl.direction;

const MainWrapper = ({
  router: { location },
  children,
  customizer,
  rtl,
  theme,
  auth,
  appConfig,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function authFetchData() {
      if (cookieUser !== null) {
        await dispatch(authRequest(cookieUser));
        await dispatch(fetchManageSaas(appConfig.manageSaas));
        return;
      }

      backToLogin();
    }

    authFetchData();
  }, [dispatch, appConfig.manageSaas]);

  if (!auth.checked) {
    return null;
  }

  return (
    <div>
      {auth.loading ? (
        <Loading loading={true} />
      ) : (
        <div
          className={`${theme.className} ${direction(location, rtl)}-support`}
          dir={direction(location, rtl)}
        >
          <div className={wrapperClass(customizer)}>{children}</div>
        </div>
      )}
    </div>
  );
};

MainWrapper.propTypes = {
  customizer: CustomizerProps.isRequired,
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
  children: PropTypes.element.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    appConfig: state.appConfig,
    theme: state.theme,
    rtl: state.rtl,
    customizer: state.customizer,
    isFetching: state.appConfig.isFetching,
  };
};

export default withRouter(connect(mapStateToProps)(MainWrapper));

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../../shared/components/form/FileInput';


const KillForm = ({
  modal,
  toggle,
  handleSubmit,
  selectedFile,
  setSelectedFile,
}) => {
  const { t } = useTranslation('common');

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName="ltr-support"
      className="modal-dialog--primary theme-light"
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">
          {t('leads.kill_form.title_form')}
        </h4>
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
      </div>
      <div className="modal__body">
        <form
          className="form"
          onSubmit={handleSubmit}
          data-testid="kill-lead-form"
        >
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.kill_form.title_input_comment')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="comment"
                component="textarea"
                type="text"
                placeholder="Comment"
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t('leads.kill_form.title_input_comment_file')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="comment_file"
                  value={selectedFile}
                  onChange={(e) => setSelectedFile(e)}
                  component={renderFileInputField}
                />
              </div>
            </div>
          </div>
          <ButtonToolbar>
            <Button color="primary" type="submit">
              {t('leads.kill_form.title_button')}
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: 'kill_form',
  enableReinitialize: true,
})(KillForm);

import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { getInvoicesId, postInvoice } from '../../../../../utils/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputRange from 'react-input-range';
import _ from 'lodash';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';
import { useSelector } from 'react-redux';


const FinancialReportStepsTwoAutomaticIncomeForm = () => {
  const [disableWithdrawBtn, setDisableWithdrawBtn] = useState(false);
  const [pageData, setPageData] = useState([]);
  const { report_id, id } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const [total, setTotal] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [failedModalMessage, setFailedModalMessage] = useState('');
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    getInvoicesId(id, authUser.accessToken).then(res => {
      const total = res.data.data[0].total;
      const parties = res.data.data[0].parties;

      setTotal(total);
      setPageData(_.map(parties, (p) => {
        return {
          id: p.user_id,
          name: p.name,
          hours: p.hours,
          amount: calculateState({
              totalValue: total,
              developerValue: p.hours,
              totalDeveloperHoursValue: _.sumBy(parties, 'hours')
            })
        }
      }))
    });
  }, [id]);

  useEffect(() => {
    const amount = total - Number(_.sumBy(pageData, 'amount'));

    if(amount > 0) {
      setDisableWithdrawBtn(false);
    } else {
      setDisableWithdrawBtn(true);
    }
  }, [pageData, total])

  const calculateState = ({totalValue, totalDeveloperHoursValue, developerValue}) => {
    const number = developerValue * totalValue/totalDeveloperHoursValue;
    return Math.trunc(number*100)/100;
  }

  const calculateRange = ({rangeValue, totalValue, pageData, index}) => {
    pageData[index].amount = rangeValue;
    const availableAmount = totalValue - _.sumBy(pageData, 'amount');

    return availableAmount <= 0 ? rangeValue + availableAmount : rangeValue;
  }

  const handlePostInvoice = () => {
    let parties = {
      parties: _.map(pageData, (p) => {
        return {
          id: p.id,
          amount: p.amount
        }
      })
    }

    postInvoice(report_id, id, parties, authUser.accessToken).then(res => {
      if(res.data.success) {
        setSuccessModal(!successModal);
      } else {
        setFailedModalMessage(res.data.errors[0].message)
        setFailedModal(!failedModal);
      }
    })
  }

  const prevNavigationQuery = location.state?.prevQuery ? [...location.state.prevQuery] : [];

  return (
    <>
      <Col sm={12}>
        <h1>Step 2</h1>
        <h4>Total: {(total - _.sumBy(pageData, 'amount')).toFixed(2)} USD</h4>
      </Col>
      <Col sm={12} className="mt-3">
        <div className="card">
          <div className='card-body'>
            <div className="d-flex">
              <Col className="pl-0 col-4">
                <div className='form__form-group'>
                  <span className='form__form-group-label'>DEVELOPER NAME</span>
                </div>
              </Col>
              <Col className="pl-0 col-4">
                <div className='form__form-group'>
                  <span className='form__form-group-label'>HOURS</span>
                </div>
              </Col>
              <Col className="pl-0 col-4">
                <div className='form__form-group'>
                  <span className='form__form-group-label'>INCOME</span>
                </div>
              </Col>
            </div>
            {
              _.map(pageData, (p, i) => {
                return (
                  <div className='form'>
                    <Col className='pl-0 col-4'>
                      <div className='form__form-group'>
                        <div className='form__form-group-field mt-4 mb-4'>
                          <span>{p.name}</span>
                        </div>
                      </div>
                    </Col>
                    <Col className='pl-0 col-4'>
                      <div className='form__form-group'>
                        <div className='form__form-group-field mt-4 mb-4'>
                          <span>{p.hours}</span>
                        </div>
                      </div>
                    </Col>
                    <Col className='pl-0 col-3'>
                      <div className='form__form-group'>
                        <div className='form__form-group-field mt-4 mb-4'>
                          <InputRange
                            maxValue={total}
                            minValue={0}
                            step={1}
                            value={p.amount}
                            onChange={value => {
                              let newRangeValue = calculateRange({
                                rangeValue: value,
                                oldRangeValue: p.amount,
                                totalValue: total,
                                pageData: pageData,
                                index: i
                              })

                              setPageData(prevState => {
                                const newState = [...prevState];
                                newState[i] = {
                                  ...newState[i],
                                  amount: Math.trunc(newRangeValue*100)/100
                                }

                                return newState;
                              })
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </div>
                )
              })
            }
            <Col className='pl-0 d-flex justify-content-end col-12' style={{borderTop: "1px solid #eceff7", paddingTop: "16px"}}>
              <button className='btn btn-primary mb-0' onClick={() => handlePostInvoice()} disabled={`${!disableWithdrawBtn ? 'disabled' : ''}`}>Withdraw</button>
            </Col>
          </div>
        </div>
        <CustomModal
          successModal={successModal}
          toggleCloseModal={() => {
            setSuccessModal(!successModal);
            navigate(`/dashboard/financial-reports/${report_id}/incomes`, {
              state: { prevQuery: prevNavigationQuery, }
            });
          }}
          textModal={`The income according to invoice ${report_id} has been distributed between parties.`}
          color={"success"}
        />
        <CustomModal
          successModal={failedModal}
          toggleCloseModal={() => setFailedModal(!failedModal)}
          textModal={failedModalMessage}
          color={"danger"}
        />
      </Col>
    </>
  );
};

export default FinancialReportStepsTwoAutomaticIncomeForm;
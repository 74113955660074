import { getAllLeads, getLeadsCounts } from '../../utils/api';

export const GET_ALL_LEADS_FETCHING = 'GET_ALL_LEADS_FETCHING';
export const GET_ALL_LEADS_SUCCESS = 'GET_ALL_LEADS_SUCCESS';
export const GET_ALL_LEADS_FAIL = 'GET_ALL_LEADS_FAIL';
export const GET_LEAD_COUNTS_SUCCESS = 'GET_LEAD_COUNTS_SUCCESS';
export const GET_LEAD_COUNTS_FAIL = 'GET_LEAD_COUNTS_FAIL';
export const GET_LEAD_COUNTS_FETCHING = 'GET_LEAD_COUNTS_FETCHING';

export const getAllLeadsFail = (e) => {
  return { type: GET_ALL_LEADS_FAIL, payload: { message: e?.message } };
};

export const getAllLeadsSuccess = (data) => {
  return { type: GET_ALL_LEADS_SUCCESS, payload: data };
};

export const getLeadCountsFail = (e) => {
  return { type: GET_LEAD_COUNTS_FAIL, payload: { message: e?.message } };
};

export const getLeadCountsSuccess = (data) => {
  return { type: GET_LEAD_COUNTS_SUCCESS, payload: data };
};

export const getAllLeadsFetchingActions = (query) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: GET_ALL_LEADS_FETCHING });
    getAllLeads(query, state.auth.user.accessToken)
      .then(({ data }) => {
        dispatch(getAllLeadsSuccess(data.data));
      })
      .catch((e) => {
        dispatch(getAllLeadsFail(e));
      });
  };
};

export const getLeadCountsFetching = () => {
  return(dispatch, getState) => {
    const state = getState();

    dispatch({type: GET_LEAD_COUNTS_FETCHING});

    getLeadsCounts(state.auth.user.accessToken).then(res => {
      if(res.data.success) {
        dispatch(getLeadCountsSuccess(res.data.data))
      }
    }).catch(err => {
      dispatch(getLeadCountsFail(err))
    })
  }
}
